require("./application");

import "../stylesheets/backend.scss";
//require("../vendor/backend/adminkit-dev/static/js/app");
//require("../vendor/backend/velonic_v6.0.0/admin/dist/assets/js/app.min");



//import 'datatables.net'
//import 'datatables.net-bs';
//import 'datatables.net-responsive-bs';

require( 'jszip' );
//require( 'pdfmake' );
require( 'datatables.net-bs4' );
require( 'datatables.net-autofill-bs4' );
require( 'datatables.net-buttons-bs4' );
require( 'datatables.net-buttons/js/buttons.colVis.js' );
require( 'datatables.net-buttons/js/buttons.html5.js' );
require( 'datatables.net-buttons/js/buttons.print.js' );
require( 'datatables.net-colreorder-bs4' );
require( 'datatables.net-datetime' );
require( 'datatables.net-fixedcolumns-bs4' );
require( 'datatables.net-fixedheader-bs4' );
require( 'datatables.net-keytable-bs4' );
require( 'datatables.net-responsive-bs4' );
require( 'datatables.net-rowgroup-bs4' );
require( 'datatables.net-rowreorder-bs4' );
require( 'datatables.net-scroller-bs4' );
require( 'datatables.net-searchbuilder-bs4' );
require( 'datatables.net-searchpanes-bs4' );
require( 'datatables.net-select-bs4' );
require( 'datatables.net-staterestore-bs4' );



//import "chartkick/highcharts"

import "chartkick"
import Highcharts from "highcharts"

window.Highcharts = Highcharts

Highcharts.setOptions({
    lang: {
        months: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin',
            'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
            weekdays: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi',
                'Jeudi', 'Vendredi', 'Samedi'],
                shortMonths: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil',
                    'Aout', 'Sept', 'Oct', 'Nov', 'Déc'],
                    decimalPoint: ',',
                    downloadPNG: 'Télécharger en image PNG',
                    downloadJPEG: 'Télécharger en image JPEG',
                    downloadPDF: 'Télécharger en document PDF',
                    downloadSVG: 'Télécharger en document Vectoriel',
                    exportButtonTitle: 'Export du graphique',
                    loading: 'Chargement en cours...',
                    printButtonTitle: 'Imprimer le graphique',
                    resetZoom: 'Réinitialiser le zoom',
                    resetZoomTitle: 'Réinitialiser le zoom au niveau 1:1',
                    thousandsSep: ' ',
                    decimalPoint: ','
    }
});




//import 'bootstrap/dist/js/bootstrap'
import "@fortawesome/fontawesome-free/css/all";
//import '@fortawesome/fontawesome-free';



// DATATABLES

/* LAST IMPORT */
//require("../vendor/bracket/lib/datatables.net-dt/js/dataTables.dataTables.min")
//require("../vendor/bracket/lib/datatables.net/js/jquery.dataTables.min")

//require("../vendor/bracket/lib/datatables.net-responsive/js/dataTables.responsive.min")
//require("../vendor/bracket/lib/datatables.net-responsive-dt/js/responsive.dataTables.min")




//require("../vendor/backend/bracket/lib/DataTables/DataTables-1.13.6/js/jquery.dataTables.min")
//require("../vendor/backend/bracket/lib/DataTables/Responsive-2.5.0/js/dataTables.responsive.min")




//require("../vendor/bracket/lib/DataTables/Buttons-2.4.2/js/buttons.dataTables.min")
//require("../vendor/bracket/lib/DataTables/Responsive-2.5.0/js/responsive.bootstrap.min")


// BRACKET TEMPLATE JS
require("../vendor/backend/bracket/lib/jquery-ui/ui/widgets/datepicker")
//import "../vendor/bracket/lib/bootstrap/js/bootstrap.bundle.min";

import PerfectScrollbar from "../vendor/backend/bracket/lib/perfect-scrollbar/perfect-scrollbar.min";
window.PerfectScrollbar = PerfectScrollbar;

//import "../vendor/bracket/lib/moment/min/moment.min";
//import "../vendor/bracket/lib/peity/jquery.peity.min";
//import "../vendor/bracket/lib/rickshaw/vendor/d3.min;";
//import "../vendor/bracket/lib/rickshaw/vendor/d3.layout.min";
//import "../vendor/bracket/lib/rickshaw/rickshaw.min";
//import "../vendor/bracket/lib/jquery.flot/jquery.flot";
//import "../vendor/bracket/lib/jquery.flot/jquery.flot.resize";
//import "../vendor/bracket/lib/flot-spline/js/jquery.flot.spline.min";
//import "../vendor/bracket/lib/jquery-sparkline/jquery.sparkline.min";
//import "../vendor/bracket/lib/echarts/echarts.min";



import select2 from "../vendor/backend/bracket/lib/select2/dist/js/select2.full.min";
window.select2 = select2;



//import Treeviz from "../vendor/backend/bracket/lib/treeviz/dist/treeviz";
//window.Treeviz = Treeviz;

require("../vendor/backend/bracket/lib/OrgChart/dist/js/jquery.orgchart.min");


require("../vendor/backend/bracket/lib/bootstrap/js/bootstrap.bundle.min");
require("../vendor/backend/bracket/js/bracket");
require("../vendor/backend/bracket/js/ResizeSensor");
require("../vendor/backend/bracket/lib/jstree/dist/jstree.min");
//require("../vendor/bracket/js/dashboard")

//require("./init_datatables")
require("./init_select2")
//require("./init_intl_tel_input")
//require("./social-share-button")
//require("./cookieconsent_init")
require("./jstree_init")
require("./custom")

//require("./arrival_mails")
//require("./imputations")
//require("./departure_mails")
require("./documents")
//require("./custom_users")
//require("./packs/select2setup")
//require("./select2setup")
/*
import Choices from 'choices.js';
window.Choices = Choices;

require("./choices_setup")
*/

//require("./slimselect_setup");


require("./tickets");
require("./imputations");


